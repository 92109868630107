<template>
  <p class="clearfix mb-0">
	<span class="float-md-left d-block d-md-inline-block mt-25">
	COPYRIGHT  © {{ new Date().getFullYear() }}
	<b-link
	class="ml-25"
	href="https://www.tabalise.fr"
	target="_blank"
	>{{ appName }}  - {{ appVersion }}</b-link>
	<span class="d-none d-sm-inline-block"> - Tous les droits sont réservés</span>
	</span>

    <span class="float-md-right d-none d-md-block">Fabriqué à la main &amp; Fabriqué avec le
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
	
export default {
	components: {
		BLink,
	},
	setup() {
		const { appName, appVersion } = $themeConfig.app
		return {
		  appName,
		  appVersion,
		}
	},
}
</script>
