export default [
	{
	title: 'Tableau de bord',
	route: 'home',
	icon: 'HomeIcon',
	},
	{
	title: 'Statistiques',
	route: 'stat',
	icon: 'BarChart2Icon',
	},
]
