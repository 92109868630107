<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-none d-lg-flex">
		<b-navbar-nav class="nav">
			<b-img
				left
				height='35'
				block
				alt="TABALISE"
				:src="'https://api.buxida.com/img/buxida.svg'"
			/>
		</b-navbar-nav>
		<b-navbar-nav 
			v-if="userData"
			class="nav"
		>
			<b-nav-item
				v-b-tooltip.hover.top="'Accueil'"
				:to="{ name: 'home' }"
			>
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="flat-primary"
				class="btn-icon "
			>
				<feather-icon 
					size="25"
					icon="HomeIcon" 
				/>
			</b-button>
			</b-nav-item>
  		</b-navbar-nav>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
		<b-button
			v-if="userData"
			v-b-tooltip.hover.top="'Changer de mot de passe'"
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			variant="flat-primary"
			class="btn-icon "
			@click="resetpass()"
		>
			<feather-icon 
				  size="20"
				  icon="KeyIcon" 
			/>
		</b-button>
		<b-button
			v-if="!userData"
			v-b-tooltip.hover.top="'Connexion'"
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			variant="flat-primary"
			class="btn-icon "
			:to="{ name: 'auth-login' }"
		>
			<feather-icon 
				  size="20"
				  icon="UserIcon" 
			/>
		</b-button>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BImg, BNavItem, VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {

    // Navbar Components
    BNavbarNav,
	BNavItem,
    UserDropdown,
	BImg,
	BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
    	userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
	  openurl3: function (url) {   
		  window.open(url, '_self')
	  },
    resetpass() {
		this.$http.post('/auth/forgotpassword', { email: this.userData.email })
			.then(() => {
				// const { rData } = response.data.response
				// console.log(rData)
				this.$toast({
				component: ToastificationContent,
				props: {
				  title: 'Un email vous a été envoyé.',
				  icon: 'EditIcon',
				  variant: 'success',
				},
			  })
			})
    },
  },
}
</script>
